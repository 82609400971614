import { Trans, useTranslation } from 'react-i18next';
import { font } from 'styles/variables';
import { useContext, useEffect, useState } from 'react';
import { useNumber } from 'hooks/useNumber';
import KpiContainer from 'components/kpi/KpiContainer';
import KpiContent from 'components/kpi/KpiContent';
import KpiListItem, { KpiList } from 'components/kpi/KpiListItem';
import PopUp from 'components/info/PopUp';
import QuestionMarkTooltipIcon from 'assets/icons/question_mark_32.svg?react';
import SubscriptionStatisticsContext from 'contexts/SubscriptionStatisticsContext';
import TooltipContentComponent from 'components/info/TooltipContent';
import TreeIcon from 'assets/icons/trees_large.svg?react';
import WarningIcon from 'assets/icons/ic_warning_solid.svg?react';
import WarningPopup from 'assets/icons/warning.svg?react';
import styled from 'styled-components';
import useCo2 from 'hooks/useCo2';

const StyledSpan = styled.span`
    vertical-align: -5%;
    font-size: ${font.size.xs};
`;

function Co2Kpi(): JSX.Element {
    const { t } = useTranslation();
    const organisationIds = useContext(SubscriptionStatisticsContext);
    const [hasError, setHasError] = useState(false);
    const { formatNumber } = useNumber();

    const {
        co2Data,
        error: errorCo2,
        isLoading: isLoadingCo2,
    } = useCo2({
        businessIds: organisationIds,
    });

    useEffect(() => {
        if (!isLoadingCo2 && errorCo2) {
            setHasError(true);
        }
    }, [setHasError, errorCo2, isLoadingCo2]);

    const treeCount = co2Data?.savedTrees ?? 0;

    return (
        <KpiContainer
            dataTestId="kpiCo2"
            title={
                <Trans
                    i18nKey="subscription.statistics.kpi.co2.title"
                    components={{
                        sp: <StyledSpan />,
                    }}
                />
            }
            subtitle={t('subscription.statistics.kpi.co2.subtitle')}
            tooltip={
                <>
                    {hasError && (
                        <PopUp
                            icon={<WarningIcon />}
                            dataTestId="kpiCo2Error"
                            content={
                                <TooltipContentComponent
                                    headline={t('subscription.statistics.kpi.error.headline')}
                                    text={t('subscription.statistics.kpi.error.text')}
                                    icon={<WarningPopup />}
                                />
                            }
                        />
                    )}
                    <PopUp
                        dataTestId="kilometerInfo"
                        content={
                            <TooltipContentComponent
                                headline={t('subscription.statistics.kpi.infoBoxCo2.headline')}
                                text={t('subscription.statistics.kpi.infoBoxCo2.text')}
                                icon={<QuestionMarkTooltipIcon />}
                            />
                        }
                    />
                </>
            }
            content={
                <>
                    <KpiContent
                        icon={<TreeIcon />}
                        text={
                            !hasError ? (
                                <Trans i18nKey="subscription.statistics.kpi.co2.savedTrees" count={treeCount}>
                                    {{ numberOfTrees: formatNumber(treeCount) }}
                                </Trans>
                            ) : (
                                '-'
                            )
                        }
                    />
                    <KpiList>
                        <KpiListItem
                            title={t('usageType.PUBLIC')}
                            value={!hasError ? `${formatNumber(co2Data?.publicCO2Saving ?? 0)} kg` : '-'}
                        />
                        <KpiListItem
                            title={t('usageType.INTERNAL')}
                            value={!hasError ? `${formatNumber(co2Data?.internalCO2Saving ?? 0)} kg` : '-'}
                        />
                        <KpiListItem
                            title={t('usageType.HOME')}
                            value={!hasError ? `${formatNumber(co2Data?.homeCO2Saving ?? 0)} kg` : '-'}
                        />
                    </KpiList>
                </>
            }
        />
    );
}

export default Co2Kpi;
